<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section section-ground-banner with-route-bar">
      <div class="items">
        <div
          class="item"
          :style="{
            'background-image': `url(${require('@/assets/img/fo/bg-3.jpg')})`
          }"
        >
          <span v-html="$t('public.shop_header')"></span>
        </div>
      </div>
    </section>

    <section class="section section-inner">
      <div class="container m-container-only-s">
        <div class="content-cols">
          <div class="left-col">
            <img src="@/assets/img/hero-shop.png" alt="" />
            <p v-html="$t('public.shop_text_1')"></p>
          </div>
          <div class="legals">
            <p v-html="$t('public.shop_text_2')"></p>
          </div>
          <div class="right-col">
            <h1>{{ $t("public.shop_text_3") }}<span class="sup">*</span></h1>

            <p>
              {{ $t("public.shop_text_4") }}
            </p>

            <ol>
              <li>
                <h3>{{ $t("public.shop_text_5") }}</h3>
                <p>
                  {{ $t("public.shop_text_6") }}
                  <img src="@/assets/i/nav-red.svg" alt="" class="nav-icon" />
                </p>
                <img src="@/assets/img/shop-img-1.png" class="screen" alt="" />
                <p>
                  {{ $t("public.shop_text_7") }}
                </p>
              </li>
              <li>
                <h3>{{ $t("public.shop_text_8") }}</h3>
                <img
                  src="@/assets/img/shop-img-2.png"
                  class="screen small"
                  alt=""
                />
              </li>
              <li>
                <h3>{{ $t("public.shop_text_9") }}</h3>
                <p>{{ $t("public.shop_text_10") }}</p>
                <img src="@/assets/img/shop-img-3.png" class="screen" alt="" />
                <p>{{ $t("public.shop_text_11") }}</p>
              </li>
              <li>
                <h3>{{ $t("public.shop_text_12") }}</h3>
                <p>
                  {{ $t("public.shop_text_13") }}
                  <img src="@/assets/i/nav-red.svg" alt="" class="nav-icon" />
                </p>
                <img
                  src="@/assets/img/shop-img-4.png"
                  class="screen small"
                  alt=""
                />
                <ul>
                  <li>{{ $t("public.shop_text_14") }}</li>
                  <li>{{ $t("public.shop_text_15") }}</li>
                  <li>{{ $t("public.shop_text_16") }}</li>
                  <li>{{ $t("public.shop_text_17") }}</li>
                </ul>
              </li>
              <li>
                <h3>{{ $t("public.shop_text_18") }}</h3>
                <img src="@/assets/img/shop-img-5.png" class="screen" alt="" />
                <p>{{ $t("public.shop_text_19") }}</p>
                <p>{{ $t("public.shop_text_20") }}</p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "ShopPublic",
  title() {
    return this.$t("pages.shop");
  }
};
</script>

<style scoped></style>
